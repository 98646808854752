(function ($) {
  gnavOfferMobileV1 = {
    formatter: $('.gnav-offer-mobile-v1')
  };

  Drupal.behaviors.gnavOfferMobileV1 = {
    get: function (key) {
      return gnavOfferMobileV1[key];
    },

    set: function (key, val) {
      gnavOfferMobileV1[key] = val;
    },

    attach: function (context, settings) {
      var self = this;
      var formatter = self.get('formatter');

      formatter.each(function () {
        var thisFormatter = $(this);
        var close_label = thisFormatter.attr('data-close-label');
        var offer_status = thisFormatter.attr('data-offer-status');

        // check lbo cookie
        if ($.cookie('offer_info') != null && $.cookie('offer_info').length > 0) {
          $('.offer-promo').show();
          show_link_based_offer();
        } else if (offer_status == 1) {
          show_gnav_offer();
        }

        function show_link_based_offer() {
          var offer_code = $.cookie('offer_info').split(':')[1];

          generic.jsonrpc.fetch({
            method: 'offer.linkedOfferState',
            params: [offer_code],
            onSuccess: function (r) {
              thisFormatter.replaceWith(r.getValue().linked_offer.cms_content);
              thisFormatter = $('.link-based-offer-v1');
              thisFormatter.removeClass('link-based-offer-v1').addClass('link-based-offer-mobile-v1');
              var subHeading = thisFormatter.find('.subheading');

              subHeading.html(r.getValue().linked_offer.offer_message);

              // Validate Duplicate Headers
              var offerHeading = thisFormatter.find('h2 span');

              if (offerHeading.is(':empty')) {
                var offerMsgFromMgr = subHeading.find('h2');

                if (!offerMsgFromMgr.is(':empty') && offerMsgFromMgr != null) {
                  offerHeading.text(offerMsgFromMgr.text());
                }
              }
              thisFormatter.find('.subheading').html(r.getValue().linked_offer.offer_message);
              thisFormatter.find('.outer-wrap').once().prepend('<div title="' + close_label + '" class="link-based-offer-close" />');
              thisFormatter.find('.link-based-offer-close').once().on('click', function() {
                thisFormatter.slideUp();
                // create session cookie
                $.cookie('hide_link_based_offer', '1', {
                  path: '/'
                });
              });
              // SET Call To Action Link Color and Font Size
              var cta_font_size = thisFormatter.find('.cta-link').attr('data-cta-link-font-size');
              var ctacolor = thisFormatter.find('.cta-link').attr('data-cta-link-color');

              thisFormatter.find('.cta-link a').css({
                'color': ctacolor,
                'font-size': cta_font_size + 'px'
              });

              // To Check SKUID or dynamic Image
              var fetchData = function (args) {
                generic.jsonrpc.fetch({
                  method: 'prodcat',
                  params: [args],
                  onSuccess: function (jsonRpcResponse) {
                    args.callback(jsonRpcResponse.getValue());
                  },
                  onError: function (jsonRpcResponse) {}

                });
              };
              var image_or_skuid = thisFormatter.attr('data-image-or-skuid');

              if (image_or_skuid > 0) {
                var sku_id = thisFormatter.find('.link-based-offer-image').attr('data-skuid');

                if (sku_id !== null && sku_id.length !== 0) {
                  var prodThumbImage = '';
                  var jsonRpcParams = {};

                  jsonRpcParams.skus = ['SKU' + sku_id];
                  jsonRpcParams.sku_fields = ['PRODUCT_ID'];
                  jsonRpcParams.callback = function (responseData) {
                    if (responseData !== null && responseData.length !== 0) {
                      if (responseData.skus !== null && responseData.skus.length !== 0) {
                        var prodjsonParams = {};

                        prodjsonParams.product_fields = ['THUMBNAIL_IMAGE'];
                        prodjsonParams.products = responseData.skus[0].PRODUCT_ID;
                        prodjsonParams.callback = function (productData) {
                          if (productData !== null && productData.length !== 0) {
                            if (productData.products !== null && productData.products.length !== 0) {
                              prodThumbImage = productData.products[0].THUMBNAIL_IMAGE;
                              thisFormatter.find('.link-based-offer-image').attr('src', prodThumbImage);
                            }
                          }
                        };
                      }
                      fetchData(prodjsonParams);
                    }
                  };
                }
                fetchData(jsonRpcParams);
              }
            },
            onFailure: function (r) {

            }
          });
        }

        function show_gnav_offer() {
          // create close button
          thisFormatter.find('.outer-wrap').once().prepend('<div title="' + close_label + '" class="gnav-offer-mobile-close" />');
          thisFormatter.find('.gnav-offer-mobile-close').once().on('click', function() {
            thisFormatter.slideUp();
            // create session cookie
            $.cookie('hide_gnav_offer_mobile', '1', {
              path: '/'
            });
          });

          // check session cookie
          var cookie = $.cookie('hide_gnav_offer_mobile');

          // if cookie does not exist
          if (cookie != '1') {
            // check offer status
            var offerCode = thisFormatter.attr('offer-code');

            // offer code is an optional field
            if (offerCode && offerCode.length > 0) {
              // check offer code timing status
              if ($('body').hasClass('env-cms') == false) {
                generic.jsonrpc.fetch({
                  method: 'offer',
                  params: [offerCode],
                  onSuccess: function (r) {
                    var offer = r.getValue();
                    var isActive = offer && (offer.timing === 'active');

                    if (isActive) {
                      // if active, show
                      thisFormatter.slideDown();
                    }
                  },
                  onFailure: function (r) {
                    // started pg server?
                  }
                });
              }
            } else {
              thisFormatter.slideDown();
            } // end offer code check
          } // end cookie check
        }
      });
    }
  };
})(jQuery);
